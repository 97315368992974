import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import GetInvestmentProductsQuery from '@/modules/flagship/catalogs/investment-products/application/queries/get-investment-products-query';
import GetSearchByCustomerQuery from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';
import GetGlobalTrackingQuery from '@/modules/flagship/investor-goal/global-tracking/application/queries/get-global-tracking-query';
import GetCustomerInvestorGoalReminderQuery
  from '@/modules/flagship/customer-investor-goal-reminder/application/queries/get-customer-investor-goal-reminder-query';
import {
  GetAllianzAccountQuery,
} from '@/modules/my-investment/allianz/allianz-account/application/queries';

// Domain
import { SearchByCustomerDto } from '@/modules/flagship/investor-goal/search-by-customer/domain/dtos/search-by-customer-dto';
import { GlobalTrackingEntity } from '@/modules/flagship/investor-goal/global-tracking/domain/entities/global-tracking-entity';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class ProgressCardViewModel {
  @Inject(TYPES.GET_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_investment_products_query!: GetInvestmentProductsQuery;

  @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.GET_GLOBAL_TRACKING_QUERY)
  private readonly get_global_tracking_query!: GetGlobalTrackingQuery;

  @Inject(TYPES.GET_CUSTOMER_INVESTOR_GOAL_REMINDER_QUERY)
  private readonly get_customer_investor_goal_reminder_query!: GetCustomerInvestorGoalReminderQuery;

  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.goals-dashboard.progress-card';

  private readonly customer_id = sessionStorage.getItem('user_id');

  private readonly error_allianz_account_not_exists = `Error customer_id:${this.customer_id}, not exists`;

  private search_by_customer_dto: SearchByCustomerDto = {
    reload: true,
    associated_product_id: '',
    is_active: true,
  };

  global_tracking: GlobalTrackingEntity = {
    global_tracking_percent: 0,
    current_global_balance: 0,
    expected_global_balance: 0,
  };

  is_loading = false;

  associated_product_ids: Array<string> = [];

  has_active_goals = false;

  main_message = '';

  secondary_message_class = '';

  secondary_message = '';

  img = 'goals-dashboard/details-goals/be_careful.svg';

  img_message = '';

  window_number = 0;

  maximum_number_windows = 2;

  emergency_fund_monthly_required_amount = 0;

  custom_monthly_required_amount = 0;

  retirement_monthly_required_amount = 0;

  has_a_goal_created_in_less_than_24_hours = false;

  day_to_remind = 0;

  is_subscribed = false;

  associated_product_id_pocket = '';

  user_has_at_least_one_linked_goal_pocket = false;

  user_has_at_least_one_linked_goal_wealth = false;

  recurrent_amount = 0;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async () => {
    this.is_loading = true;
    await this.loadInvestmentProducts();
    await this.checkIfUserHasAtLeastOneLinkedGoal();
    if (this.has_active_goals) {
      await this.loadGlobalTracking();
    }
    this.is_loading = false;
  }

  get show_recurrent_contributions() {
    return this.user_has_at_least_one_linked_goal_wealth && this.recurrent_amount;
  }

  get total_amount_recurrent_contributions() {
    return currencyFormat(this.recurrent_amount);
  }

  get total_monthly_required_amount_formatted() {
    const total_amount = this.emergency_fund_monthly_required_amount + this
      .custom_monthly_required_amount + this.retirement_monthly_required_amount;
    return currencyFormat(total_amount);
  }

  loadInvestmentProducts = async () => {
    try {
      const investment_products = await this.get_investment_products_query.execute();
      Object.entries(investment_products).forEach((item: any) => {
        this.associated_product_ids.push(item[1].id);
        if (item[1].name === 'sowos_pocket') {
          this.associated_product_id_pocket = item[1].id;
        }
      });
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_investment_products_query'));
    }
  }

  checkIfUserHasAtLeastOneLinkedGoal = async () => {
    // eslint-disable-next-line prefer-destructuring
    this.search_by_customer_dto.associated_product_id = this.associated_product_ids[0];
    await this.loadActiveGoals();
    // eslint-disable-next-line prefer-destructuring
    this.search_by_customer_dto.associated_product_id = this.associated_product_ids[1];
    await this.loadActiveGoals();
  }

  loadActiveGoals = async () => {
    try {
      // eslint-disable-next-line max-len
      const active_goals = await this.get_search_by_customer_query.execute(this.search_by_customer_dto);
      if (active_goals.length) {
        this.has_active_goals = true;
        const emergency_fund = active_goals.find((item) => item.emergency_fund_investor_goal);
        if (emergency_fund) {
          this.emergency_fund_monthly_required_amount = emergency_fund.monthly_required_amount;
          this.validateShowGlobalMonitoring(emergency_fund.initial_investment_date);
        }
        const retirement_fund = active_goals.find((item) => item.retirement_investor_goal);
        if (retirement_fund) {
          this.user_has_at_least_one_linked_goal_wealth = true;
          this.retirement_monthly_required_amount = retirement_fund.monthly_required_amount;
          this.validateShowGlobalMonitoring(retirement_fund.initial_investment_date);
          await this.loadRecurrentAssignedAmount();
        }
        const amount_linked_custom_goals = active_goals.map(
          (item) => ((item.custom_investor_goal) ? item.monthly_required_amount : 0),
        );
        if (amount_linked_custom_goals) {
          const customized_goals = active_goals.filter((item) => item.custom_investor_goal);
          customized_goals.forEach((item) => {
            this.validateShowGlobalMonitoring(item.initial_investment_date);
            if (item.associated_product_id !== this.associated_product_id_pocket) {
              this.user_has_at_least_one_linked_goal_wealth = true;
            }
          });
          if (this.user_has_at_least_one_linked_goal_wealth) {
            await this.loadRecurrentAssignedAmount();
          }
          this.custom_monthly_required_amount += amount_linked_custom_goals.reduce(
            (total, currentValue) => total + currentValue, 0,
          );
        }
        // eslint-disable-next-line max-len
        const pocket_goals = active_goals.find((goal) => goal.associated_product_id === this.associated_product_id_pocket);
        if (pocket_goals) {
          await this.loadCustomerInvestorGoalReminder(this.associated_product_id_pocket);
          this.user_has_at_least_one_linked_goal_pocket = true;
        }
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_active_goals'));
    }
  }

  loadGlobalTracking = async () => {
    try {
      this.global_tracking = await this.get_global_tracking_query.execute();
      // eslint-disable-next-line max-len
      this.global_tracking.global_tracking_percent = (this.global_tracking.global_tracking_percent > 100) ? 100 : Number(this.global_tracking.global_tracking_percent.toFixed(0));
      this.setGoalProgressStatus(this.global_tracking.global_tracking_percent);
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_global_tracking_query'));
    }
  }

  setGoalProgressStatus = (percent: number) => {
    switch (true) {
      case percent >= 80:
        this.main_message = this.translate('keep_it_up');
        this.secondary_message = this.translate('keep_it_up_message');
        this.secondary_message_class = 'secondary--text';
        this.img = 'goals-dashboard/details-goals/keep_it_up.svg';
        this.img_message = this.translate('keep_it_up_message');
        break;
      case (percent >= 65 && percent < 80):
        this.main_message = this.translate('youre_doing_well_message');
        this.secondary_message = this.translate('youre_doing_well');
        this.secondary_message_class = 'sky-blue--text';
        this.img = 'goals-dashboard/details-goals/youre_doing_well.svg';
        this.img_message = this.translate('youre_doing_well');
        break;
      case (percent >= 50 && percent < 65):
        this.main_message = this.translate('you_can_do_better');
        this.secondary_message = this.translate('you_can_do_better_message');
        this.secondary_message_class = 'progress-blue--text';
        this.img = 'goals-dashboard/details-goals/you_can_do_better.svg';
        this.img_message = this.translate('you_can_do_better_img');
        break;
      default:
        this.main_message = this.translate('be_careful_message');
        this.secondary_message = this.translate('be_careful');
        this.secondary_message_class = 'red--text';
        this.img = 'goals-dashboard/details-goals/be_careful.svg';
        this.img_message = this.translate('be_careful');
        break;
    }
  }

  loadCustomerInvestorGoalReminder = async (associated_product_id: string) => {
    try {
      // eslint-disable-next-line max-len
      const customer_investor_goal_reminder = await this.get_customer_investor_goal_reminder_query.execute({
        associated_product_id,
      });
      if (customer_investor_goal_reminder) {
        this.day_to_remind = customer_investor_goal_reminder.day_to_remind;
        this.is_subscribed = customer_investor_goal_reminder.is_subscribed || false;
      }
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_customer_investor_goal_reminder'));
      }
    }
  };

  loadRecurrentAssignedAmount = async () => {
    try {
      // eslint-disable-next-line max-len
      const { confirm_periodicity, amount } = await this.get_allianz_account_query.execute({ customer_id: this.customer_id });
      if (confirm_periodicity) {
        this.recurrent_amount = amount || 0;
      }
    } catch (error) {
      if (error.message !== this.error_allianz_account_not_exists) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_recurrent_assigned_amount'));
      }
    }
  }

  validateShowGlobalMonitoring = (initial_investment_date: string) => {
    const date_to_validate = new Date(initial_investment_date);
    const current_date = new Date();
    date_to_validate.setDate(date_to_validate.getDate() + 1);
    if (current_date <= date_to_validate) {
      this.has_a_goal_created_in_less_than_24_hours = true;
    }
  }
}
