















































































































































































import { Component, Vue } from 'vue-property-decorator';
import LoadingDataCard from '@/vue-app/components/home-dashboard/LoadingDataCard.vue';
import ProgressCardViewModel from '@/vue-app/view-models/components/goals-dashboard/progress-card-view-model';

@Component({
  name: 'ProgressCard',
  components: {
    LoadingDataCard,
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class ProgressCard extends Vue {
  progress_card_view_model = Vue.observable(new ProgressCardViewModel());

  created() {
    this.progress_card_view_model.initialize();
  }
}
